'use strict';

import http from '@/utils/http';
// import { getFormData } from '@/utils';
import { validMobile, validEmail } from '@/utils/validation';

export default {
  getCaptcha(randomId) {
    return http
      .get(`/authority/anno/captcha?key=${randomId}`, {
        responseType: 'arraybuffer',
      })
      .then(
        data =>
          `data:image/png;base64,${btoa(
            String.fromCharCode(...new Uint8Array(data))
          )}`
      );
  },
  login(data) {
    return http.post('/connections_treasure_box_admin_backend/manage/manage-user/manageLoginByAccount',data);
  },
  logout(code, params) {
    return http.get('/authority/anno/loginOut', { params, code });
  },
  getTenant(params) {
    return http.get('/authority/anno/getTenant', { params });
  },
  getCode(data) {
    if (validMobile(data)) {
      return http.post('/issmart-messages/message/sendVerifyCode', {
        phone: data,
        sendChannel: 'SMS',
      });
    }
    if (validEmail(data)) {
      return http.post('/issmart-messages/message/sendVerifyCode', {
        email: data,
        sendChannel: 'EMAIL',
      });
    }
  },
  verifyCode({ account, code }) {
    if (validMobile(account)) {
      return http.post('/issmart-messages/message/checkVerifyCode', {
        phone: account,
        verifyCode: code,
      });
    }
    if (validEmail(account)) {
      return http.post('/issmart-messages/message/checkVerifyCode', {
        email: account,
        verifyCode: code,
      });
    }
  },
  verifyAccount(data) {
    let temp = {};
    if (validMobile(data)) {
      temp.mobile = data;
    } else if (validEmail(data)) {
      temp.email = data;
    }
    return http.post('/authority/user/judgment/emailOrPhone', temp);
  },
};
